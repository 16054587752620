import useTree from '../../../tree/useTree';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import FullScreenImageViewer from '../../../components/FullScreenImageViewer/FullScreenImageViewer';
import { Accordion, AccordionItem, Button } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { useTreeInfoProperties } from '../../../properties/usePropertyConfigurations';
import { CloseLarge, Launch } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';

export default function QuickTreeDetails() {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const treeId = urlContext.getTreeId() ?? '';
  const { tree } = useTree(account.organization.id, treeId);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const closeImageViewer = () => setSelectedImageUrl('');

  const thumbnailUrl = tree?.getMainImageThumbnailURL();

  const [imageUri, setImageUri] = useState<string | null>(null);
  const [openAccordion, setOpenAccordion] = useState<string | null>('');

  useEffect(() => {
    if (!tree) return;
    if (!thumbnailUrl) return;

    tree.getMainImageThumbnailFromLayersURL?.()
      .then((i: any) => setImageUri(i))
      .catch(() => setImageUri(thumbnailUrl as string));
  }, [tree?.id, thumbnailUrl]);

  const imageContainerStyle: CSSProperties = tree?.hasImages()
    ? { backgroundImage: `url('${imageUri}')` }
    : {};

  const { dimensionProperties, ecosystemProperties, healthProperties, safetyProperties } = useTreeInfoProperties();

  const accordions: { title: string, properties: DisplayableTreeProperty[] }[] = [
    { title: 'treePropertyGroups.dimensions', properties: dimensionProperties },
    { title: 'treePropertyGroups.ecosystemServices', properties: ecosystemProperties },
    {
      title: 'treePropertyGroups.healthIndication',
      properties: healthProperties
    },
    { title: 'treePropertyGroups.safety', properties: safetyProperties }
  ];

  if (!tree) return <></>;
  const navigateToTreeDetails = () => navigate(`/organizations/${account.organization.id}/inventory/trees/${tree.id}`);

  return (
    <>
      <PageLayout.RightPanelContent>
        <div className="p-4 h-full">
          <div className="flex justify-between items-center mb-8 text-[var(--cds-text-primary)]">
            <div className="text-xl">{`${t('treeDetails.tree')} ${tree.externalId}`}</div>
            <div className="p-2 cursor-pointer" onClick={() => urlContext.setTreeId('')}><CloseLarge /></div>
          </div>
          <div
            className="h-44 bg-center bg-cover bg-black-haze-600 cursor-pointer"
            style={imageContainerStyle}
            onClick={() => setSelectedImageUrl(tree?.getMainImageURL() ?? '')}
          />
          <div className="my-4 text-sm">
            {/*TODO: apply italic to taxonomy fields based on specification*/}
            <div className="flex items-center gap-2 ml-4">
              <div className="text-[var(--cds-text-secondary)] w-20">{t('tree.genus')}</div>
              <div className="text-[var(--cds-text-primary)]">
                <i>{tree.genus}</i>
              </div>
            </div>
            <div className="flex items-center gap-2 ml-4">
              <div className="text-[var(--cds-text-secondary)] w-20">{t('tree.species')}</div>
              <div className="text-[var(--cds-text-primary)]">
                <i>{tree.species}</i>
              </div>
            </div>
          </div>
          <Accordion>
            {accordions.map((it, idx) => (
              <AccordionItem
                title={t(it.title)}
                key={it.title}
                className={`!border-1 ${openAccordion === it.title ? '[&>button>div]:text-[var(--cds-link-primary)] !border-t-[var(--cds-interactive)] !border-t-1' : '!border-[var(--cds-border-strong)]'} ${(openAccordion && (accordions.find(a => a.title === openAccordion) === accordions[idx - 1])) ? '!border-t-[var(--cds-interactive)] !border-t-1' : ''} ${(openAccordion && accordions.find(a => a.title === openAccordion) === accordions.at(-1)) ? '!border !border-b-[var(--cds-interactive)] !border-b-1' : ''}`}
                open={openAccordion === it.title}
                onHeadingClick={() => setOpenAccordion(openAccordion === it.title ? null : it.title) }
              >
                {it.properties.map((property, index) => {
                  const unit = Tree.getUnit(property, account.organization);
                  const value =
                property === DisplayableTreeProperty.SafetyFactors
                  ? tree?.[Tree.getDefaultSafetyFactorProperty(account)]
                  : tree?.[property];

                  return (
                    <div
                      key={`tree-${index}-${property}`}
                      className="flex items-center justify-between border-0 border-solid border-b last:border-b-0 border-[var(--cds-border-subtle-00)] py-3 px-4 text-sm">
                      <div className="text-[var(--cds-text-secondary)]">{t(`tree.${property}`)}</div>
                      <div className="text-[var(--cds-text-primary)] flex gap-0.5">
                        {(value === null || value === undefined) ? ('-') : (
                          <>
                            {typeof value === 'number' ? new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }).format(value) : value || '-'}
                            {unit && <>{t('units.' + unit)}</>}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </AccordionItem>
            ))}

          </Accordion>
        </div>
      </PageLayout.RightPanelContent>
      <PageLayout.RightPanelActions>
        <Button className="float-right w-1/2" size={'xl'} renderIcon={Launch} onClick={navigateToTreeDetails}>
          {t('treeInfo.details')}
        </Button>
      </PageLayout.RightPanelActions>
      {selectedImageUrl !== '' && <FullScreenImageViewer url={selectedImageUrl} onClose={closeImageViewer} />}
    </>
  );
}
