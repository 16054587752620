import { RadarChartData, RadarChartProperty } from './RadarChart';
import DetailedTree from '../../../tree/DetailedTree';

export class RadarChartCalculator {
  private readonly structureProperties: RadarChartProperty[];
  private readonly healthAndVitalityProperties: RadarChartProperty[];
  private readonly clearancesProperties: RadarChartProperty[];
  private readonly benefitsProperties: RadarChartProperty[];
  private readonly allometricAnomaliesProperties: RadarChartProperty[];

  constructor(
    readonly tree: DetailedTree
  ) {
    this.structureProperties = [
      { label: 'Trunk stability (base)', value: this.tree.safetyFactorAt80Kmh ? this.tree.safetyFactorAt80Kmh >= 3 : null },
      { label: 'Trunk stability (final)', value: null },
      { label: 'Leaning angle', value: this.tree.leaningAngle ? this.tree.leaningAngle <= 30 : null },
      { label: 'Slenderness', value: (this.tree.ageClass && this.tree.slenderness) ? (this.tree.ageClass === 'young' || this.tree.slenderness <= 200) : null },
      { label: 'Dieback', value: this.tree.dieback ? this.tree.dieback <= 60 : null }
    ];
    this.healthAndVitalityProperties = [
      { label: 'Dieback', value: this.tree.dieback ? this.tree.dieback <= 60 : null },
      { label: 'Crown Transparency', value: this.tree.crownTransparency ? !['dead', 'severelyDefoliated'].includes(this.tree.crownTransparency) : null },
      { label: 'Parasite(s)', value: null },
      { label: 'Disease(s)', value: null },
      { label: 'NDVI', value: this.tree.ndvi ? this.tree.ndvi >= 0.5 : null }
    ];
    this.clearancesProperties = [
      { label: 'Wire safe', value: this.tree.wireClearanceIssuesDetected === null ? null : !this.tree.wireClearanceIssuesDetected },
      { label: 'Road safe', value: this.tree.roadClearanceIssuesDetected === null ? null : !this.tree.roadClearanceIssuesDetected },
      { label: 'Building safe', value: null },
      { label: 'Traffic signs safe', value: null },
      { label: 'Viaduct safe', value: null }
    ];
    this.benefitsProperties = [
      { label: 'CO₂ sequestration', value: this.tree.outlierCoSequestration === null ? null : !this.tree.outlierCoSequestration },
      { label: 'PM₂.₅ reduction', value: this.tree.outlierPmReduction === null ? null : !this.tree.outlierPmReduction },
      { label: 'Oxygen production', value: this.tree.outlierOxygenProduction === null ? null : !this.tree.outlierOxygenProduction },
      { label: 'Water runoff', value: this.tree.outlierWaterRunoff === null ? null : !this.tree.outlierWaterRunoff },
      { label: 'Evapotranspiration', value: this.tree.outlierEvapotranspiration === null ? null : !this.tree.outlierEvapotranspiration }
    ];
    this.allometricAnomaliesProperties = [
      { label: 'DBH vs Leaf Area', value: this.tree.outlierTrunkDiameterPerLeafArea === null ? null : !this.tree.outlierTrunkDiameterPerLeafArea },
      { label: 'Height vs Crown Volume', value: this.tree.outlierHeightPerCrownVolume === null ? null : !this.tree.outlierHeightPerCrownVolume },
      { label: 'DBH vs Height', value: this.tree.outlierTrunkDiameterPerHeight === null ? null : !this.tree.outlierTrunkDiameterPerHeight },
      { label: 'Leaf Area vs Crown Volume', value: this.tree.outlierLeafAreaPerCrownVolume === null ? null : !this.tree.outlierLeafAreaPerCrownVolume },
      { label: 'DBH vs Crown Volume', value: this.tree.outlierTrunkDiameterPerCrownVolume === null ? null : !this.tree.outlierTrunkDiameterPerCrownVolume }
    ];
  }

  getSummarizedScore(): number {
    return 0; // TODO: Implement
  }

  getData(): RadarChartData {
    return {
      summarizedScore: this.getSummarizedScore(),
      sections: [
        {
          label: 'Structure',
          value: this.structureProperties.filter(p => p.value).length,
          noDataCount: this.structureProperties.filter(p => p.value === null).length,
          properties: this.structureProperties
        },
        {
          label: 'Health',
          value: this.healthAndVitalityProperties.filter(p => p.value).length,
          noDataCount: this.healthAndVitalityProperties.filter(p => p.value === null).length,
          properties: this.healthAndVitalityProperties
        },
        {
          label: 'Clearances',
          value: this.clearancesProperties.filter(p => p.value).length,
          noDataCount: this.clearancesProperties.filter(p => p.value === null).length,
          properties: this.clearancesProperties
        },
        {
          label: 'Benefits',
          value: this.benefitsProperties.filter(p => p.value).length,
          noDataCount: this.benefitsProperties.filter(p => p.value === null).length,
          properties: this.benefitsProperties
        },
        {
          label: 'Anomalies',
          value: this.allometricAnomaliesProperties.filter(p => p.value).length,
          noDataCount: this.allometricAnomaliesProperties.filter(p => p.value === null).length,
          properties: this.allometricAnomaliesProperties
        }
      ]
    };
  }
}
