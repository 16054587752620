import { Button, Header, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { Flippers } from '../../switches/Flippers';
import { useCurrentAccount } from '../../account/useAccounts';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import {
  ArrowLeft,
  ChartViolinPlot,
  Notification,
  SettingsAdjust,
  UserMultiple,
  UserRole,
  UserXRay
} from '@carbon/icons-react';
import { Navigate, useLocation, useMatch, useNavigate } from 'react-router-dom';
import UserPreferences from './UserPreferences';
import useCarbonThemes from '../../components/UI/Carbon/useCarbonThemes';
import { RolesAndPermissions } from './RolesAndPermissions';

enum Tab {
  User = 'user',
  Roles = 'roles',
  Members = 'members',
  Organization = 'organization',
  DataStandards = 'data-standards',
  CreateOrganization = 'create-organization'
}

export default function CarbonSettings() {
  useCarbonThemes();
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const navigate = useNavigate();

  const organization = account.organization;
  const match = useMatch('/organizations/:organizationId/settings/:tab');
  const authContext = useContext(AuthContext);
  const user = authContext.user;

  const getSettingsLinkForTab = (tab: Tab) => {
    return '/organizations/' + organization.id + '/settings/' + tab;
  };

  const currentTab = window.location.href.split('/').pop();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      const iframe = document.getElementById('jsd-widget');
      if (iframe) iframe.style.display = 'block';

      if (!document.getElementById('jsd-widget-script')) {
        const script = document.createElement('script');
        script.setAttribute('data-jsd-embedded', '');
        script.setAttribute('id', 'jsd-widget-script');
        script.setAttribute('data-key', 'e328c564-26fa-4c0b-9f3e-357288e8e0c4');
        script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
        script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
          window.document.dispatchEvent(new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true
          }));
        });
      }
    }
    return () => {
      const iframe = document.getElementById('jsd-widget');
      if (iframe) iframe.style.display = 'none';
    };
  }, []);

  if (currentTab === 'settings' || '') return <Navigate replace to={getSettingsLinkForTab(Tab.User)} />;

  return (
    <div className="normalize-scrollbar">
      <div style={{ backgroundColor: 'var(--cds-background)' }} className="w-full h-full fixed top-0 -z-10">
      </div>
      <Header className="flex gap-[10px] items-center" aria-label="header">
        <Button
          kind="ghost"
          className="flex items-center"
          onClick={() => navigate('/organizations/' + organization.id + '/inventory')}
        >
          <ArrowLeft />
        </Button>
        <h1 className="text-sm font-semibold text-[var(--cds-text-primary)]">
          {t('settings.settingsAndPreferences')}
        </h1>
      </Header>
      <SideNav
        className="mx-12 mt-4"
        expanded={true}
        isChildOfHeader={false}
        isFixedNav={false}
        aria-label="Side navigation">
        <SideNavItems>
          <SideNavLink
            renderIcon={UserXRay}
            onClick={() => navigate(getSettingsLinkForTab(Tab.User))}
            isActive={currentTab === Tab.User}
            className='h-12 cursor-pointer'
          >
            {t('settings.userPreferences')}
          </SideNavLink>
          {organization.isEnabled(Flippers.release24q3) && (
            <SideNavLink
              renderIcon={UserRole}
              onClick={() => navigate(getSettingsLinkForTab(Tab.Roles))}
              className='h-12 cursor-pointer'
              isActive={currentTab === Tab.Roles}
            >
              {t('settings.rolesAndPermissions')}
            </SideNavLink>
          )}
          {account.canManageMembers() && (
            <SideNavLink
              renderIcon={UserMultiple}
              onClick={() => navigate(getSettingsLinkForTab(Tab.Members))}
              className='h-12 cursor-pointer'
              isActive={currentTab === Tab.Members}
            >
              {t('settings.organizationMembers')}
            </SideNavLink>
          )}
          {account.canEditOrganizationSettings() && (
            <SideNavLink
              renderIcon={SettingsAdjust}
              onClick={() => navigate(getSettingsLinkForTab(Tab.Organization))}
              className='h-12 cursor-pointer'
              isActive={currentTab === Tab.Organization}
            >
              {t('settings.organizationSettings')}
            </SideNavLink>
          )}
          {account.canEditDataStandards() && (
            <SideNavLink
              renderIcon={ChartViolinPlot}
              onClick={() => navigate(getSettingsLinkForTab(Tab.DataStandards))}
              className='h-12 cursor-pointer'
              isActive={currentTab === Tab.DataStandards}
            >
              {t('settings.dataStandards.label')}
            </SideNavLink>
          )}
          {user.isSuperOwner() && (
            <SideNavLink
              renderIcon={Notification}
              onClick={() => navigate(getSettingsLinkForTab(Tab.CreateOrganization))}
              className='h-12 cursor-pointer'
              isActive={currentTab === Tab.CreateOrganization}
            >
              {t('settings.createOrganization.title')}
            </SideNavLink>
          )}
        </SideNavItems>
      </SideNav>
      <div className="mt-20 w-full">
        {match?.params.tab === 'user' && <UserPreferences />}
        {match?.params.tab === 'roles' && <RolesAndPermissions />}
      </div>
    </div>
  );
}
