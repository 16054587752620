import { Radar } from 'react-chartjs-2';
import { useRef } from 'react';
import { Chart } from 'chart.js';
import { useTranslation } from 'react-i18next';
import useCarbonThemes, { CarbonTheme } from '../../../components/UI/Carbon/useCarbonThemes';
import { CheckmarkFilled, CloseFilled, ErrorFilled } from '@carbon/icons-react';

export default function RadarChart(props: RadarChartProps) {
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();
  const radarRef = useRef(null);

  const isLightTheme = [CarbonTheme.g10, CarbonTheme.white].includes(theme);

  const lightGreen = 'rgba(164, 246, 214, 0.8)';
  const darkGreen = 'rgba(0, 145, 111, 0.8)';

  const plugin = {
    id: 'labelBackground',
    beforeDraw: (chart, args, options) => {
      const padding = 8;
      const { ctx } = chart;
      const labels = chart.scales.r._pointLabels;

      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';

      labels.forEach((label, index) => {
        const background = isLightTheme ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';
        const border = isLightTheme ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)';
        const selectedBackground = isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.4)';
        const selectedBorder = isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.4)';

        const labelPosition = chart.scales.r.getPointLabelPosition(index);
        ctx.fillStyle = index === options.selectedIndex ? selectedBackground : background;
        ctx.strokeStyle = index === options.selectedIndex ? selectedBorder : border;
        ctx.lineWidth = 1; // Border width

        ctx.beginPath();
        ctx.roundRect(
          labelPosition.left - padding,
          labelPosition.top - padding,
          labelPosition.right - labelPosition.left + 2 * padding,
          labelPosition.bottom - labelPosition.top + 2 * padding,
          1000
        );
        ctx.fill();
        ctx.stroke();
      });

      ctx.restore();
    },
    defaults: {
      selectedIndex: 0
    }
  };

  const data = {
    labels: props.data.sections.map(it => `${it.label} · ${it.value}/${it.properties.length - it.noDataCount} - ${t('treeDetails.noData')}: ${it.noDataCount}`),
    datasets: [{
      data: props.data.sections.map(it => it.value / it.properties.length * 5),
      fill: true,
      backgroundColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? darkGreen : lightGreen,
      borderColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? darkGreen : lightGreen,
      pointBackgroundColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? darkGreen : lightGreen,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? darkGreen : lightGreen,
      tension: 0.4
    }, {
      data: props.data.sections.map(it => (it.value + it.noDataCount) / it.properties.length * 5),
      fill: true,
      backgroundColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? lightGreen : darkGreen,
      borderColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? lightGreen : darkGreen,
      pointBackgroundColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? lightGreen : darkGreen,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? lightGreen : darkGreen,
      tension: 0.4
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      labelBackground: {
        selectedIndex: props.selectedSectionIdx,
        hoveredIndex: -1
      }
    },
    elements: {
      line: {
        borderWidth: 0
      },
      point: {
        radius: 0
      }
    },
    scales: {
      r: {
        grid: {
          circular: true,
          color: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? 'rgba(71, 71, 71, 1)' : 'rgba(232, 232, 232, 1)'
        },
        angleLines: {
          color: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? 'rgba(71, 71, 71, 1)' : 'rgba(232, 232, 232, 1)'
        },
        pointLabels: {
          color: [CarbonTheme.g10, CarbonTheme.white].includes(theme) ? 'rgba(71, 71, 71, 1)' : 'rgba(232, 232, 232, 1)',
          font: {
            size: 14
          },
          padding: 20
        },
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
          display: false
        }
      }
    },
    layout: {
      padding: 5
    },
    aspectRatio: 2.5,
    onClick: event => {
      const padding = 8;
      for (let i = 0; i < event.chart.data.labels.length; i++) {
        const label = event.chart.scales.r.getPointLabelPosition(i);
        if (label.left - padding < event.x && event.x < label.right + 2 * padding && label.top - padding < event.y && event.y < label.bottom + 2 * padding) {
          event.chart.config.options.plugins.labelBackground.selectedIndex = i;
          event.chart.update();
          props.setSelectedSectionIdx(i);
        }
      }
    }
  };
  return (
    <>
      <Radar
        ref={radarRef}
        data={data}
        options={options}
        plugins={[plugin]}
        onMouseMove={event => {
          if (!radarRef.current) return;
          const chart = Chart.getChart(radarRef.current) as any;
          if (!chart) return;
          const rect = chart.canvas.getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;

          const padding = 8;

          let hoveredIndex = -1;
          for (let i = 0; i < chart.data.labels!.length; i++) {
            const label = chart.scales.r.getPointLabelPosition(i);
            if (label.left - padding < x && x < label.right + padding && label.top - padding < y && y < label.bottom + padding) {
              hoveredIndex = i;
              break;
            }
          }
        chart.config.options!.plugins!.labelBackground.hoveredIndex = hoveredIndex;
        chart.update();
        }}
        onMouseLeave={() => {
          if (!radarRef.current) return;
          const chart = Chart.getChart(radarRef.current) as any;
          if (!chart) return;
        chart.config.options!.plugins!.labelBackground.hoveredIndex = -1;
        chart.update();
        }}
      />
      <div className="px-4 mt-8 text-sm">
        <h5 className="font-semibold my-3">{props.data.sections[props.selectedSectionIdx].label}</h5>
        <ul>
          {
            props.data.sections[props.selectedSectionIdx].properties
              .map(it => (
                <li className="my-1.5 flex items-center gap-2" key={it.label}>
                  <div>{it.value === null ? <ErrorFilled fill="#F1C21B" /> : (it.value ? <CheckmarkFilled fill="#24A145" /> : <CloseFilled fill="#DA211E"/>)}</div>
                  <div>{it.label}</div>
                </li>)
              )
          }
        </ul>
      </div>
    </>
  );
}

export interface RadarChartProps {
  data: RadarChartData,
  selectedSectionIdx: number,
  setSelectedSectionIdx: (idx: number) => void
}

export type RadarChartData = {
  summarizedScore: number,
  sections: {
    label: string,
    value: number,
    noDataCount: number,
    properties: RadarChartProperty[]
  }[]
};

export type RadarChartProperty = {
  label: string,
  value: boolean | null
};
